const theme = {
  colors: {
    background: '#f9f9f9',
    white: '#ffffff',
    textPrimary: '#333333',
    textSecondary: '#555555',
    border: '#e0e0e0',
    link: '#007acc',
    linkHover: '#005f99',
    footerText: '#666666',
    subtleText: '#d1d1d1'
  },
  font: {
    body: "'Inter', sans-serif"
  }
}

export default theme
