import styled from '@emotion/styled'
import React from 'react'

const Container = styled.div`
  font-family: ${(props) => props.theme.font.body};
  background-color: ${(props) => props.theme.colors.background};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0;
`

const MainContent = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
`

interface LayoutProps {
  children: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => (
  <Container>
    {/* <Header /> */}
    <MainContent>{children}</MainContent>
    {/* <Footer /> */}
  </Container>
)

export default Layout
