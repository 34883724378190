import React from 'react'
import { ThemeProvider } from '@emotion/react'
import theme from './src/theme'
import CalendlyFloatingButton from './src/components/calendly'
import Layout from './src/components/layout'

export const wrapRootElement = ({ element }: { element: React.ReactNode }) => (
  <ThemeProvider theme={theme}>
    <Layout>
      {element}
      <CalendlyFloatingButton />
    </Layout>
  </ThemeProvider>
)
