import React, { useEffect } from 'react'

const CalendlyFloatingButton: React.FC = () => {
  useEffect(() => {
    // Add Calendly CSS
    const link = document.createElement('link')
    link.href = 'https://assets.calendly.com/assets/external/widget.css'
    link.rel = 'stylesheet'
    document.head.appendChild(link)

    // Add Calendly JS
    const script = document.createElement('script')
    script.src = 'https://assets.calendly.com/assets/external/widget.js'
    script.async = true
    document.body.appendChild(script)

    // Initialize widget after script loads
    script.onload = () => {
      // @ts-ignore (since Calendly is loaded globally)
      window.Calendly.initBadgeWidget({
        url: 'https://calendly.com/jocampom?primary_color=1a1a1a',
        text: 'Chat with me',
        color: '#1a1a1a',
        textColor: '#fefefe',
        branding: false
      })
    }

    // Cleanup function
    return () => {
      document.head.removeChild(link)
      document.body.removeChild(script)
    }
  }, [])

  return null
}

export default CalendlyFloatingButton
